<template>
  <div>
    <h3 class="page-title">ياردەم باشقۇرۇش</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <p class="mytitle mytitle-ug">مەزمۇن (ئۇيغۇرچە):</p>
            <div v-html="props.row.content_ug" class="mycontent mycontent-ug"></div>
            <el-divider></el-divider>
            <p class="mytitle mytitle-zh">内容（中文）:</p>
            <div v-html="props.row.content_zh" class="mycontent"></div>
          </template>
        </el-table-column>
        <el-table-column prop="title_ug" label="ماۋزۇ (ئۇيغۇرچە)"></el-table-column>
        <el-table-column prop="title_zh" label="ماۋزۇ (خەنزۇچە)"></el-table-column>
        <el-table-column prop="status" width="120" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status==1">ئوچۇق</label>
            <label v-show="scope.row.status==0">ئېتىك</label>
          </template>
        </el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="65%"
      top="3vh"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="ماۋزۇ (ئۇيغۇرچە)" prop="title_ug">
            <el-input v-model="ruleForm.title_ug" placeholder="ماۋزۇ (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="ماۋزۇ (خەنزۇچە)" prop="title_zh">
            <el-input v-model="ruleForm.title_zh" class="ltr-left" placeholder="标题（中文）"></el-input>
          </el-form-item>
          <el-form-item label="مەزمۇن (ئۇيغۇرچە)" prop="content_ug" class="ltr-left">
            <vue-ueditor-wrap v-model="ruleForm.content_ug" :config="myConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item label="مەزمۇن (خەنزۇچە)" prop="content_zh" class="ltr-left">
            <vue-ueditor-wrap v-model="ruleForm.content_zh" :config="myConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .mytitle{
    color: #909399;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .mycontent{
    direction: initial;
    text-align: initial;
  }
  .mytitle-ug,.mycontent-ug{
    direction: rtl;
    text-align: right;
  }
  .mytitle-zh{
    direction: ltr;
    text-align: left;
  }
</style>

<script>
  var self;

  import baseConfig from '../base.config.js'
  var home_url = baseConfig.baseUrl + 'editor/controller.php';
  console.log(home_url);
  import VueUeditorWrap from 'vue-ueditor-wrap' // ES6 Module

  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          title_ug: "",
          title_zh: "",
          content_ug:"",
          content_zh:"",
          status: "1"
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",
        pageTags:{},
        myConfig: {
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          // 初始容器高度
          initialFrameHeight: 500,
          // 初始容器宽度
          initialFrameWidth: '100%',
          // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
          // serverUrl: 'http://35.201.165.105:8000/controller.php',
          // serverUrl: 'https://durdana-api.hamdam.biz/editor/controller.php',
          serverUrl: home_url,
          // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
          UEDITOR_HOME_URL: '/UEditor/'
        }
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/help").then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags")); 
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.title_ug = row.title_ug;
        self.ruleForm.title_zh = row.title_zh;
        self.ruleForm.content_ug = row.content_ug;
        self.ruleForm.content_zh = row.content_zh;
        self.ruleForm.status = row.status + '';
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/help/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(formName) {
        var myData = {
          title_ug: self.ruleForm.title_ug,
          title_zh: self.ruleForm.title_zh,
          content_ug:self.ruleForm.content_ug,
          content_zh:self.ruleForm.content_zh,
          status: self.ruleForm.status
        }

        if(self.addDialog){
          self.$post('admin/help',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/help/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          title_ug: "",
          title_zh: "",
          content_ug:"",
          content_zh:"",
          status: "1"
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      }
    },
    components: {
      VueUeditorWrap
    }
  };
</script>
